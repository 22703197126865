/*!

=========================================================
* BootstrapVue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard-pro
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import Vue2Editor from "vue2-editor";
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.filter('moneyFormat', function(amount) {
  if (typeof amount !== 'number' || isNaN(amount)) {
    return '';
  }
  if(Math.abs(amount) < 0.000001){
    amount = 0
  }
  return amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
})

// router setup
import router from './routes/router';
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Vue2Editor);

Vue.config.productionTip = false;
/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  pinia
});
