import {useAuthStore} from '@/stores/authStore'

export const version = '0.0.5';

export const apiDomain = process.env.VUE_APP_BACKEND
export const LoginURL = apiDomain + 'api/login'
export const homeLink = process.env.VUE_APP_FRONTEND
export const fileServer = apiDomain

export const clientId = null
export const clientSecret = null

export const getHeader = function () {
    // const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
    const headers = {
        'Accept' : 'application/json',
        'Authorization' : 'Bearer ' + tokenData.access_token
    }

    return headers
}

export const getFileHeader = function () {
  const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
  const headers = {
      'Accept' : 'application/json',
      'Authorization' : 'Bearer ' + tokenData.access_token,
      'Content-Type': 'multipart/form-data'
  }

  return headers
}

export const getcurrentDate = function ()
{
  const current       = new Date()
  const currentMonth  = String((current.getMonth()+1)).padStart(2, '0')
  const currentDay    = String(current.getDate()).padStart(2, '0')
  const currentDate   = current.getFullYear()+'-'+currentMonth+'-'+currentDay

  return currentDate
}

export const getcurrentDateTime = function ()
{
  const current       = new Date()
  const currentMonth  = String((current.getMonth()+1)).padStart(2, '0')
  const currentDay    = String(current.getDate()).padStart(2, '0')
  const currentDateTIme   = current.getFullYear()+'-'+currentMonth+'-'+currentDay+' '+current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();

  return currentDateTIme
}


export const getUserInfo = function () {
    return useAuthStore().auth_user
}
