import { defineStore } from 'pinia'
import {LoginURL, apiDomain, getHeader} from '@/assets/js/config.js'
import axios from 'axios'

export const useAuthStore = defineStore('authStore', {
  state:() => ({
    auth_loading: false,
    auth_user: null,
    auth_erorr: null,
    permissions: {
        edit_booking_fees: false,
        return_unit: false,
        change_unit: false,
        change_info: false,
        add_or_delete_joint:false,
        manage_bank:false,
        change_sign_date:false,
        assign_and_update_la_spa_status: false,
        remarks_comment: false,
        is_approver:false,
        is_reviewer:false,
    },
  }),
  actions : {
    clearUser(){
        this.auth_user = null
        localStorage.clear()
    },
    async login(email, password){  
        const form_data = {
            email : email,
            password : password,
        }

        try {
            this.auth_erorr = null
            this.auth_loading = true
            const res = await axios.post(LoginURL, form_data)

            var authUser = {
                access_token: res.data.access_token,
                refresh_token: res.data.refresh_token
            }
            window.localStorage.setItem('authUser', JSON.stringify(authUser))

            var loginUser = {
                id: res.data.user.id,
                role_id: res.data.user.role_id,
                role_name: res.data.user.role_name,
                name: res.data.user.name,
                email: res.data.user.email,
                original_user_id: res.data.user.original_user_id,
                is_reviewer: res.data.user.is_reviewer,
                is_approver: res.data.user.is_approver,
            }

            window.localStorage.setItem('loginUser', JSON.stringify(loginUser))
            
            this.auth_user = loginUser
            this.setPermissions(this.auth_user.role_id)
            this.auth_loading = false
        }
        catch (e) {
            console.log(e.response)
            this.auth_loading = false
            this.auth_erorr = e.response.data.message
            this.clearUser()
        }
    },
    async fetchUserInfo(token = null){
        if(token){
            var authUser = {
                access_token: token,
            }
            window.localStorage.setItem('authUser', JSON.stringify(authUser));
        }

        if(localStorage.getItem("authUser") === null){
            return;
        }
        
        try {
            const res = await axios.get(apiDomain + 'setup/user.info', { headers: getHeader() })
            var loginUser = {
                id: res.data.user.id,
                role_id: res.data.user.role_id,
                role_name: res.data.user.role_name,
                name: res.data.user.name,
                email: res.data.user.email,
                original_user_id: res.data.user.original_user_id,
                is_reviewer: res.data.user.is_reviewer,
                is_approver: res.data.user.is_approver,
                is_finance: res.data.user.is_finance,
            }

            window.localStorage.setItem('loginUser', JSON.stringify(loginUser))
            this.auth_user = loginUser
            this.setPermissions(this.auth_user.role_id)
            this.setReviewersAndApprovers()
        }catch(e) {
            this.clearUser()
            console.log(e.response.data.message)
        }
    },
    async logout(){
        this.clearUser()
    },
    setReviewersAndApprovers(){
        if(this.auth_user.is_reviewer){
            this.permissions.is_reviewer = true
        }
        if(this.auth_user.is_approver){
            this.permissions.is_approver = true
        }
    },
    setFinance(){
        if(this.auth_user.is_finance){
            this.permissions.is_finance = true
        }
    },
    setPermissions(role_id){
        //admin
        if(role_id == 2){
            this.permissions.edit_booking_fees = true,
            this.permissions.return_unit = true,
            this.permissions.change_unit = true,
            this.permissions.change_info = true,
            this.permissions.add_or_delete_joint =true,
            this.permissions.manage_bank =true,
            this.permissions.change_sign_date =true,
            this.permissions.assign_and_update_la_spa_status = true,
            this.permissions.remarks_comment = true
            this.setReviewersAndApprovers()
            this.setFinance()
        }

        //user
        if(role_id == 3){
            this.permissions.edit_booking_fees = true,
            this.permissions.return_unit = true,
            this.permissions.change_unit = false,
            this.permissions.change_info = true,
            this.permissions.add_or_delete_joint =true,
            this.permissions.manage_bank =true,
            this.permissions.change_sign_date =true,
            this.permissions.assign_and_update_la_spa_status = true,
            this.permissions.remarks_comment = true
            this.setReviewersAndApprovers()
            this.setFinance()
        }

        //manager
        if(role_id == 4){
            this.permissions.edit_booking_fees = true,
            this.permissions.return_unit = true,
            this.permissions.change_unit = false,
            this.permissions.change_info = true,
            this.permissions.add_or_delete_joint =true,
            this.permissions.manage_bank =false,
            this.permissions.change_sign_date =false,
            this.permissions.assign_and_update_la_spa_status = false,
            this.permissions.remarks_comment = false
            this.setReviewersAndApprovers()
            this.setFinance()
        }

        //operator
        if(role_id == 5){
            this.permissions.edit_booking_fees = false,
            this.permissions.return_unit = false,
            this.permissions.change_unit = false,
            this.permissions.change_info = false,
            this.permissions.add_or_delete_joint =false,
            this.permissions.manage_bank =true,
            this.permissions.change_sign_date =false,
            this.permissions.assign_and_update_la_spa_status = false,
            this.permissions.remarks_comment = false
            this.setReviewersAndApprovers()
            this.setFinance()
        }

        //processor
        if(role_id == 6){
            this.permissions.edit_booking_fees = true,
            this.permissions.return_unit = true,
            this.permissions.change_unit = false,
            this.permissions.change_info = true,
            this.permissions.add_or_delete_joint =true,
            this.permissions.manage_bank =true,
            this.permissions.change_sign_date =false,
            this.permissions.assign_and_update_la_spa_status = false,
            this.permissions.remarks_comment = false
            this.setReviewersAndApprovers()
            this.setFinance()
        }
    }
  }
})